import React from 'react'
import "./../Consultation/ConsultationStyle.css"


function Consultation() {
  return (
    <div className='convenience_fulll_width'>
    <div className='main_consultation_picc'>
    <div className='convenience_textt' >Schedule A Free <br/><strong>Consultation</strong> Today</div>
    <div className='below_convenience' >Selling your home has never been so easy!</div>
    <button  className='ofr_btn' >Get My Offer Now!</button>
    <div className='call_text' >Or Call Us For FREE <strong> 866-928-4635</strong></div>

    
    </div>
</div>
  )
}

export default Consultation
